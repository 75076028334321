<template>
  <div>
    <b-modal ref="modalUserRegisterMessage" size="lg" hide-footer title="We have created a CV Screen account for you">
        <div class="d-block">
            <p>
                An account does not exist in CV Screen with your provided email address, 
                therefore we have created one for you. Please check your email inbox, including junk mail, 
                for an email containing a verification link. You may verify your account at a later stage to gain access to your dashboard. 
                For now, please proceed with completing your National Police Check application.
            </p>
        </div>
    </b-modal>
  </div>
</template>
<script>
export default {
    name:"ModalUserRegisterMessahe",
    components: {
        
    },
    data(){
        return{
 
        }
    },
    computed:{

    },
    methods:{
        fnShowModal(){
            this.showModal();
        },
        hideModal(){
            this.$refs.modalUserRegisterMessage.hide();
        },
        showModal(){
            this.$refs.modalUserRegisterMessage.show();
        },
    }
}
</script>