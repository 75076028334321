<template>
  <div class="p-3">
    <div class="row">
      <div class="col-xl-9 col-lg-9 col-md-9 col-12 h-100">
        <appProgressSteps :propsData="gettStepId"></appProgressSteps>
      </div>
      <div class="col-xl-3 col-lg-3 col-md-3 col-12 h-100">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-12 text-left">
                <img
                  src="../../assets/img/cv-screen-marketing-1.jpeg"
                  style="height: 77px"
                  class=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <section v-if="gettStepId == 1">
      <appStep1 :mode="mode" :way="way"> </appStep1>
    </section>

    <appModalUserRegisterMessahe ref="modalUserRegisterMessage">
    </appModalUserRegisterMessahe>
  </div>
</template>
<script>
import appStep1 from "../../components/policeCheck/step1.vue";
import appProgressSteps from "../../components/policeCheck/progressSteps.vue";
import { mapGetters, mapActions } from "vuex";
import appModalUserRegisterMessahe from "../../components/admin/user/ModalUserRegisterMessage.vue";

export default {
  components: {
    appStep1,
    appProgressSteps,
    appModalUserRegisterMessahe,
  },
  computed: {
    ...mapGetters(["gettStepId", "getPoliceCheckSingle"]),
  },
  data() {
    return {
      mode: "new",
      way: "direct",
    };
  },
  methods: {
    ...mapActions(["fetchStepId", "fetchPoliceCheckSingle"]),
    fnModalUserRegisterMessage() {
      this.$refs.modalUserRegisterMessage.fnShowModal();
    },
  },
  mounted() {
    this.fnModalUserRegisterMessage();
  },
  created() {
    this.fetchStepId(1);
  },
};
</script>
